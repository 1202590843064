import type { And, FieldOperations, Not, Or } from 'algolia-search-builder/build/types/operators';
import type { SearchOptions, MultipleQueriesQuery } from '@algolia/client-search';
import type { Filters } from './filters.interface';
import type { DeepWriteable } from '~/domains/app/interfaces/utility.interface';
import type { SEARCH_ALL_INDEX, SearchHit } from './search.interface';

export type HaFieldsObjectQuery = {
  [Key in keyof SearchHit]?: FieldOperations | Not | string | number | Not[]
}

export interface HaAnd extends Omit<And, 'and'> {
  and?: (HaFieldsObjectQuery & HaOr)[];
}

export interface HaOr extends Omit<Or, 'or'> {
  or?: HaFieldsObjectQuery[];
}

// Common
export interface HaMultipleQueriesQuery extends Omit<DeepWriteable<MultipleQueriesQuery>, 'indexName'> {
  indexName: SEARCH_ALL_INDEX
}

// Ui
export interface HaSearchOptionsUI extends Omit<DeepWriteable<SearchOptions>, 'filters'> {
  filters: Filters
}
export interface HaMultipleQueriesQueryUI extends Omit<DeepWriteable<HaMultipleQueriesQuery>, 'params'> {
  title?: string,
  params: HaSearchOptionsUI
}

// Builder
export interface HaSearchOptionsBUILDER extends Omit<DeepWriteable<SearchOptions>, 'filters'> {
  filters: HaAnd & HaOr & HaFieldsObjectQuery
}
export interface HaMultipleQueriesqueryBuilder extends Omit<DeepWriteable<MultipleQueriesQuery>, 'params'> {
  params: HaSearchOptionsBUILDER
}

export enum QUERY_PARAMS {
  page = 'page',
  hitsPerPage = 'hitsPerPage',
  offset = 'offset',
  length = 'length',
  aroundLatLngViaIP = 'aroundLatLngViaIP',
  aroundLatLng = 'aroundLatLng',
  aroundRadius = 'aroundRadius',
  insideBoundingBox = 'insideBoundingBox'
}
