import type { LocationQuery } from 'vue-router'
import { SEARCH_ALL_INDEX, SEARCH_INDEX } from '../interfaces/search.interface'
import { QUERY_PARAMS, type HaMultipleQueriesQueryUI } from '../interfaces/query.interface'
import { FILTER_ACTIVITY, FILTER_CATEGORY, FILTER_DATE, FILTER_LOCATION, FILTER_PRICE, FILTER_TAG, type Filters } from '../interfaces/filters.interface'
import { Tabs } from '@/helpers/enums'
import { convertArrayToString, convertBBoxToString, convertStringToNumberArray } from './filters.helper'
import { convertFilterToParamThematic, convertParamThematicToFilter, convertParamsThematicToFilters } from '~/domains/thematic/helpers/filters.helper'

export const queryParamsKeys = Object.values(QUERY_PARAMS)
export const searchIndexes = Object.values(SEARCH_INDEX).filter(i => i !== SEARCH_INDEX.ALL)

export const tabToIndexes = {
  [Tabs.ALL]: searchIndexes,
  [Tabs.ACTIVITIES]: [SEARCH_ALL_INDEX.ACTIVITIES],
  [Tabs.ORGANIZATIONS]: [SEARCH_ALL_INDEX.ORGANIZATIONS],
  [Tabs.PROJECTS]: [SEARCH_ALL_INDEX.PROJECTS],
}

export const filterKeysActivity = Object.values(FILTER_ACTIVITY)
export const filterKeysCategory = Object.values(FILTER_CATEGORY)
export const filterKeysLocation = Object.values(FILTER_LOCATION)
export const filterKeysPrice = Object.values(FILTER_PRICE)
export const filterKeysDate = Object.values(FILTER_DATE)
export const filterKeysTag = Object.values(FILTER_TAG)

export const filterKeysAll = [
  ...filterKeysActivity,
  ...filterKeysCategory,
  ...filterKeysLocation,
  ...filterKeysPrice,
  ...filterKeysDate,
  ...filterKeysTag,
]

export const filterKeysByIndex = {
  [SEARCH_ALL_INDEX.ALL]: filterKeysAll,
  [SEARCH_ALL_INDEX.ACTIVITIES]: filterKeysAll,
  [SEARCH_ALL_INDEX.ORGANIZATIONS]: [
    ...filterKeysCategory,
    ...filterKeysLocation,
    ...filterKeysTag,
  ],
  [SEARCH_ALL_INDEX.PROJECTS]: [
    ...filterKeysCategory,
    ...filterKeysLocation,
    ...filterKeysTag,
  ],
}

export function mapResultsByKeys<T>(queryKeys: string[], results: any[]) {
  const resultsByKey: Record<string, T> = {}
  if (!results?.length) {
    throw new Error('Result is empty')
  }
  if (queryKeys.length !== results.length) {
    console.warn('algolia.helper.mapResultsByKeys > QueryKeys length is not same of results length')
  }
  for (const key of queryKeys) {
    const indexOfQuery = queryKeys.indexOf(key)
    resultsByKey[key] = results[indexOfQuery]
  }
  return resultsByKey
}

export function queryToParamsUrl(
  query: HaMultipleQueriesQueryUI,
  ignore?: string[]
): LocationQuery {

  const flattenQuery: LocationQuery = {}

  if (query.query) {
    flattenQuery.query = query.query
  }

  // Parse params
  for (const queryParamKey in query.params) {
    if (Object.hasOwn(query.params, queryParamKey)
      && queryParamKey !== 'filters'
      && !ignore?.includes(queryParamKey)
      && query.params[queryParamKey] !== undefined
      && query.params[queryParamKey] !== null
      && query.params[queryParamKey] !== ''
    ) {
      const queryParamValue = query.params[queryParamKey];
      flattenQuery[queryParamKey] = queryParamValue
    }
  }

  // Parse filters
  for (const queryFilterKey in query.params.filters) {
    if (
      Object.hasOwn(query.params.filters, queryFilterKey)
      && !ignore?.includes(queryFilterKey)
      && query.params.filters[queryFilterKey] !== undefined
      && query.params.filters[queryFilterKey] !== null
      && query.params.filters[queryFilterKey] !== ''
    ) {
      const queryParamValue = query.params.filters[queryFilterKey];
      if (typeof queryParamValue === 'object') {
        if (queryFilterKey === FILTER_PRICE.PRICE) {
          flattenQuery[queryFilterKey] = convertArrayToString(queryParamValue)
        } else {
          flattenQuery[queryFilterKey] = JSON.stringify(queryParamValue)
        }
      } else {
        flattenQuery[queryFilterKey] = queryParamValue
      }
    }
  }

  return flattenQuery
}

export const parserQueryValueString = (key: string, value: any) => {
  if (key === FILTER_PRICE.PRICE && value) {
    return convertStringToNumberArray(value)
  }
  else if (typeof value === 'string') {
    try {
      return JSON.parse(value)
    } catch (error) {
      if (value.includes(',')) {
        return value.split(',')
      } else if (
        key === FILTER_ACTIVITY.ACTIVITY_TYPES as FILTER_ACTIVITY ||
        key === FILTER_CATEGORY.CATEGORIES as FILTER_CATEGORY
      ) {
        return [value]
      } else {
        return value
      }
    }
  }
  else {
    return value
  }
}

export function paramsUrlToQuery(paramsUrl: LocationQuery, indexName = SEARCH_ALL_INDEX.ALL): HaMultipleQueriesQueryUI {
  const queryUi: HaMultipleQueriesQueryUI = {
    indexName,
    query: paramsUrl.query as string,
    params: {
      filters: {}
    }
  }

  for (const key in paramsUrl) {
    if (Object.hasOwn(paramsUrl, key)) {
      const value = paramsUrl[key];

      if (queryParamsKeys.includes(key as any)) {
        queryUi.params[key] = parserQueryValueString(key, value)
      }

      if (filterKeysByIndex[indexName].includes(key as any)) {
        queryUi.params.filters[key] = parserQueryValueString(key, value)
      }
    }
  }

  return queryUi
}

export function convertSearchParamValuesToString(query: Filters) {
  const keysExcluded = ['filters']
  const paramsCleaned: Record<string, string> = {}
  for (const key in query) {
    if (Object.hasOwn(query, key) && keysExcluded.includes(key) === false) {
      const value = query[key];
      
      // convert array to string ex: [1,2] > "1,2"
      if (typeof value === 'object' && value?.length) {
        if (key === FILTER_LOCATION.BBOX) {
          paramsCleaned[key] = convertBBoxToString(value as Filters['bbox'])
        } else {
          const valueParamThematic = value.map(val => convertFilterToParamThematic(val))
          paramsCleaned[key] = convertArrayToString(valueParamThematic)
        }
      }
      else if (typeof value === 'string' || typeof value === 'number') {
        paramsCleaned[key] = value.toString()
      }
      else if (value === null) {
        paramsCleaned[key] = null
      }
      else {
        // eslint-disable-next-line no-console
        console.warn('algolia.helper.cleanQueryUrlParams >', key, 'will be exclude by cleaning');
      }
    }
  }
  return paramsCleaned
}

export function convertSearchParamsToString(query: LocationQuery): string {
  const prepareQuery = convertSearchParamValuesToString(query)
  const urlSearchParams = new URLSearchParams(prepareQuery)
  return urlSearchParams.toString()
}


export function queryToQueryString(query: HaMultipleQueriesQueryUI, ignore?: string[]): string {
  const queryUrlParams = queryToParamsUrl(query, ignore)
  const queryUrlParamsString = convertSearchParamsToString(queryUrlParams)
  return queryUrlParamsString
}

export function createQueries(
  indexes: SEARCH_ALL_INDEX[],
  search: string,
  params: Omit<HaMultipleQueriesQueryUI['params'], 'filters'> = {},
  filters: HaMultipleQueriesQueryUI['params']['filters'] = {}
) {
  const queries: Record<string, HaMultipleQueriesQueryUI> = {}
  for (const indexName of indexes) {

    // ignore some indexes
    if ([SEARCH_ALL_INDEX.ALL].includes(indexName)) {
      // eslint-disable-next-line no-console
      console.warn(`createQueries for '${indexName}' was ignored`);
      continue
    }
    // get filters by indexes
    const indexFilters = {}
    if (typeof filterKeysByIndex[indexName] !== 'object') {
      // eslint-disable-next-line no-console
      console.warn(`createQueries for '${indexName}' has not filters`)
    }
    else {
      for (const key of filterKeysByIndex[indexName]) {
        const value = filters[key]
        if (typeof value !== 'undefined') {
          if(Array.isArray(value)) {
            indexFilters[key] = convertParamsThematicToFilters(value)
          } 
          else if(typeof value === 'string') {
            indexFilters[key] = convertParamThematicToFilter(value)
          }
          else {
            indexFilters[key] = value
          }
        }
      }
    }

    const query: HaMultipleQueriesQueryUI = {
      indexName: indexName as SEARCH_ALL_INDEX,
      title: indexName,
      query: search,
      params: {
        ...params,
        filters: {
          ...indexFilters,
        }
      }
    }

    queries[indexName] = query
  }
  return queries
}

export function queryToLocationQuery(query: HaMultipleQueriesQueryUI) {
  const locationQuery: LocationQuery = {
    query: query.query || query.params.query
  }

  for (const key in query.params) {
    if (Object.hasOwn(query.params, key)) {
      const value = query.params[key];
      locationQuery[key] = value
    }
  }

  for (const key in query.params.filters) {
    if (Object.hasOwn(query.params.filters, key)) {
      const value = query.params.filters[key];
      locationQuery[key] = value
    }
  }

  return locationQuery
}
